<template>
	<div id="tu-zindex">
		<div class="warp">
			<el-form ref="zForm" :model="form" :rules="rules" label-width="0px">
				<div class="title">Tutoredin</div>
				<div class="note">{{ $t('z.note') }}</div>
				<div class="note">
					<el-form-item prop="mobile">
						<el-input v-model="form.mobile" size="large" placeholder="Please Input Mobile"></el-input>
					</el-form-item>
				</div>
				<div style="margin-top: 2rem;">
          <el-button @click="onSendCheckEvent" class="link" :disabled="disabled">{{ $t('z.check-login') }}</el-button>
        </div>
        <div style="margin-top: 1rem;">
        	<el-link @click="$router.push('/login')" type="primary">{{ $t('router.login') }}</el-link>
        	<el-link v-if="isLogin" @click="onLogout" type="default" style="margin-left: 10px">{{ $t('quit') }}</el-link>
        </div>
		  </el-form>
		</div>
	</div>
</template>
<script>
import { mapActions } from 'vuex'
import { checkToken } from '@/utils'
import RegexTool from '@/utils/regex'

export default {
	name: "z",
	components: {
		
	},
	data() {
		return {
			isLogin: false,
			disabled: false,
			form: {
				short: null,
				mobile: null
			},
    	rules: {
    		mobile: [
					{
					    required: true,
					    message: this.$t('auth.enter-mobile-number'),
					    trigger: 'blur'
					},
					{
					    pattern: RegexTool.mobile,
					    message: this.$t('auth.enter-right-mobile'),
					    trigger: ['blur', 'change'],
					},
				],
			}
		}
	},
	created() {
		this.form.short = this.$route.params.short;

		if(checkToken() === true) {
			this.disabled = true;
			this.isLogin = true;
			this.onLoginEvent();
		}
	},
	methods: {
		...mapActions(['ShortLogin', 'Logout']),

		onSendCheckEvent() {
			this.$refs.zForm.validate((valid, value) => {
				if(!valid) {
					return false;
				}
				this.onLoginEvent();
			});
		},
		onLoginEvent() {
			let that = this
			this.ShortLogin(this.form).then(res => {
				this.$message.success(this.$t('auth.login-successful'));
				this.$store.dispatch('GetUserInfo')
				setTimeout(function(){
					if(!!res.redirect_uri) {
						that.$router.push({path: res.redirect_uri})
					} else {
						that.$router.push({path: '/dashboard'})
					}
				}, 2500);
			}, err => {
				this.disabled = false;
			})
		},
		onLogout() {
			this.Logout().then(res => {
				this.$message.success(this.$t('Success'));
				this.isLogin = false;
				this.disabled = false;
			})
		}
	}
}
</script>
<style lang="less">
#tu-zindex {
	text-align: center;
	margin: 0;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #212529;
	box-sizing: border-box;
	background-color: #fff;

  a {
  	cursor: pointer;
  }

  .warp {
	position: absolute;
	left: 50%;
	top: 40%;
	transform: translate(-50%, -40%);
	display: inline-block;
	width: 600px;
	padding: 3rem 2rem;
	box-shadow: 0 1rem 3rem rgba(0,0,0,.175) !important;
	border-radius: .25rem !important;
	background-color: #fff!important;

	.title {
		font-size: 22px; 
		font-weight: bold;
		color: var(--el-color-primary);
	}

	.subTitle {
		font-size: 18px; 
		font-weight: bold;
		color: var(--el-color-info);
	}

	.note {
		margin-top: 1rem !important;
		text-align: left;
		color: #555;
	}

	.link {
		text-decoration: none;
		padding: 12px 65px;
		border-radius: 5px;
		background: #f56c6c;
		color: #FFF;
		font-size: 15px;
	}
  }
}
</style>